
import  {useState, useEffect, useContext} from 'react';
import { Nav, NavItem, NavLink, TabContent, Button} from 'reactstrap'
import PropTypes from 'prop-types';
import DatosAlumno from './DatosAlumno';
import ActividadesGrupos from './ActividadesGrupos';
import Comentarios from './Comentarios';
import Historico from './Historico';
import Acciones from './Acciones';
import Recibos from './Recibos';
import Loader from '../../../layouts/loader/Loader';
import ModalAviso from '../../../layouts/modals/ModalAviso'
import { AuthContext } from '../../../context/authContext';

import '../../../assets/styles/login/login.css';

const ResumenSol = ({ idMatricula, setIdUsuario, handleOnClick }) => {

    const [curso, setCurso] = useState('');
    const [ccodcli, setCcodcli] = useState('')
    const [listadoRecibos, setListadoRecibos] = useState([])
    const [idBorrados, setIdBorrados] = useState([])
    const [idEditados, setIdEditados] = useState([])
    const [idEditados2, setIdEditados2] = useState([])
    const [nuevos, setNuevos] = useState([])
    const [sumatorio, setSumatorio] = useState(0);
    const [modulos, setModulos] = useState([]);
    const [activity, setActivity] = useState({});
    const [disableInput, setDisableInput] = useState(false);
    const userSession = JSON.parse(localStorage.getItem('user'));
    const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));
    const [comentarios, setComentarios] = useState('');
    const [invalidComentarios, setInvalidComentarios] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [isNoPresencial, setIsNoPresencial] = useState(null);
    const { userOU} = useContext( AuthContext );
    const [hoursCounter, setHoursCounter] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const [data, setData] = useState('')

    
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    
    useEffect(() => {
      if (userOU.centro !== '') {
        fetch(
          `${process.env.REACT_APP_NODE_API}detalleMatricula?headquarter=${enrollmentSession.headquarter}&id=${idMatricula}&userEmail=${userSession.profileObj.email}`,
         
          {
            method: 'get',
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
              'content-type': 'application/json',
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0'
            },
          },
        )
          .then((response) => response.json())
          .then((actualData) =>{
            setIdUsuario(actualData[0].idusuario)
            const adminCentros = new Set(['SUPERADMIN', 'CNTEC Girona', 'CEDESCA', 'CNTEC Sevilla', 'SECRETARÍA', 'SECRETAR\u00cdA', 'SECRETARIA']);
            // Verificar si el centro está en la lista de centros de administración
            if (adminCentros.has(userOU.centro) && actualData[0].estado === 'Revisar delegación') {
            
                // Si el centro está en la lista de centros de administración y el estado es 'Revisar delegación'
                setDisableInput(true);
            } else if ((!adminCentros.has(userOU.centro) && actualData[0].estado === 'Revisar secretaria')) {
                // Si el estado es 'Revisar secretaria' o 'Activa'
                setDisableInput(true); 
            } else if (actualData[0].estado === 'Baja' || actualData[0].estado === 'Pendiente Baja'){
              setDisableInput(true);
            }else if ((!adminCentros.has(userOU.centro) && actualData[0].estado === 'Activa')){
              setDisableInput(true);
            }

            // Guardamos el nombre del curso
            setCurso(actualData[0].curso);
            setIsNoPresencial( actualData[0].IdModalidadFormacion !== 0 );
            setCcodcli(actualData[0].ccodcli)
            setData(actualData[0])

            setIsLoading(false);
              
          })
          .catch((error) => {
            sessionStorage.setItem('apiError', error);
            localStorage.removeItem('user');
            window.location.reload();
          })
      }
     
    }, [userOU.centro]);



    let id = idMatricula;

    if (idMatricula === undefined) {
      id = JSON.parse(localStorage.getItem('enrollment')).id;
    }

    return(
        <>
        {!isLoading && userOU.centro !== '' && (userOU.centro === "SUPERADMIN" || userOU.centro === "SECRETARÍA" ||userOU.centro === "SECRETAR\u00cdA") && data.estado === 'Baja' && data.cantidadRecibos > 0 ? 
        <ModalAviso toggle={toggle} header='Matrícula con recibos pendientes.' /> : '' }
          {!isLoading && userOU.centro !== '' ? 
          <> 
          <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? 'active' : ''}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Datos matrícula
                            </NavLink>
                        </NavItem>
                        {(userOU.centro === "SUPERADMIN" || userOU.centro === "SECRETARÍA" || userOU.centro === "SECRETAR\u00cdA" ) && ( data.estado !== 'Revisar secretaria' && data.estado !== 'Revisar delegación') ?
                        <NavItem>
                        <NavLink
                                className={activeTab === '2' ? 'active' : ''}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Recibos
                            </NavLink>
                        </NavItem> 
                        : ''}
          </Nav>
          {activeTab === '1' ? <>
          <TabContent className="p-4" activeTab={activeTab}>
          <DatosAlumno  idMatricula={id}/>
          {ccodcli !== '' ? <ActividadesGrupos isNoPresencial={isNoPresencial} setModulos={setModulos} id={id} activity={activity} setActivity={setActivity}
          empresaId={JSON.parse(localStorage.getItem('enrollment')).headquarter} modulos={modulos}
          sumatorio={sumatorio} setSumatorio={setSumatorio}
          userSession={userSession}
          idMatricula={idMatricula}
          estado={disableInput} hoursCounter={hoursCounter} setHoursCounter={setHoursCounter} ccodcli={ccodcli}
          /> : ''}
          
          {userOU.centro === 'ORIENTACIÓN' ? '' : <Comentarios setComentarios={setComentarios} setInvalidComentarios={setInvalidComentarios} invalidComentarios={invalidComentarios}/>}
          <Historico />
          </TabContent>

          </> : 
          <TabContent className="p-4" activeTab={activeTab}>
            <Recibos listadoRecibos={listadoRecibos} setListadoRecibos={setListadoRecibos} idMatricula={id} idBorrados={idBorrados} setIdBorrados={setIdBorrados} setIdEditados={setIdEditados} idEditados={idEditados} setIdEditados2={setIdEditados2} idEditados2={idEditados2} nuevos={nuevos} setNuevos={setNuevos}/>
          </TabContent>
          }
           
           {userOU.centro === 'ORIENTACIÓN' ?  <Button className="float-end me-1" onClick={handleOnClick}>
                        Atrás
                    </Button> : <Acciones isNoPresencial={isNoPresencial} curso={curso} comentarios={comentarios} modulos={modulos} activity={activity}
            hoursCounter={hoursCounter} idBorrados={idBorrados} idEditados={idEditados} idEditados2={idEditados2} nuevos={nuevos}/> }
         
          </>
          
          : 
          <Loader />}
            

        </>
    )
}
ResumenSol.propTypes = {
    idMatricula: PropTypes.number,
    setIdUsuario: PropTypes.any,
    handleOnClick: PropTypes.any
  };
export default ResumenSol;