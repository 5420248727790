import { useState, useEffect, useCallback } from 'react';
import { Col, Form, Row, Input, Button, Label, Spinner } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import ModalLoader from '../../layouts/modals/ModalLoader';
import Loader from '../../layouts/loader/Loader';
import ListadoAlumnosActas from './ListadoAlumnosActas';

const FormularioActa = () => {

    const [data, setData] = useState('')
    const [headquarter, setHeadquarter] = useState('CEDESCA')
    const [courseYear, setCourseYear] = useState('2324')
    const [course, setCourse] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [isProceedingLoading, setIsProceedingLoading] = useState(false)
    const [type, setType] = useState(1)
    const [group, setGroup] = useState()
    const userSession = JSON.parse(localStorage.getItem('user'))
    const [fecha, setFecha] = useState('')
    const [listadoAlumnos, setListadoAlumnos] = useState([])
    const [alumnosIds, setAlumnosIds] = useState([])
    const [allChecked, setAllChecked] = useState(false)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_NODE_API}cargarActas?degree=1&years=2324&headquarter=CEDESCA&type=1&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((actualData) => {
                setGroup(actualData.groups[0].titulo);
                setData(actualData);
            }).catch(error => {
                sessionStorage.setItem('apiError', error)
                localStorage.removeItem('user')
                window.location.href = '/';
            });
    }, []);

    const handleStudent = useCallback((ccodcli) => {
        setAlumnosIds((prevIds) => {
            const newAlumnosIds = prevIds.includes(ccodcli)
                ? prevIds.filter(id => id !== ccodcli)
                : [...prevIds, ccodcli];
            
            // Actualizar listadoAlumnos
            setListadoAlumnos((prevList) =>
                prevList.map(alumno =>
                    alumno.ccodcli === ccodcli
                        ? { ...alumno, actions: <Input type="checkbox" id={ccodcli} name="option" checked={newAlumnosIds.includes(ccodcli)} onChange={() => handleStudent(ccodcli)} /> }
                        : alumno
                )
            );
    
            return newAlumnosIds;
        });
    }, [alumnosIds]);

    const handleHeadquarter = (e) => {
        setIsLoading(true);
        setHeadquarter(e.target.value);
        fetch(`${process.env.REACT_APP_NODE_API}cargarActas?degree=${course}&years=c&headquarter=${e.target.value}&type=${type}&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((actualData) => {
                setGroup(actualData.groups[0].titulo);
                setData(actualData);
                setIsLoading(false);
            });
    };

    const handleCourseYear = (e) => {
        setIsLoading(true);
        setCourseYear(e.target.value);
        fetch(`${process.env.REACT_APP_NODE_API}cargarActas?degree=${course}&years=${e.target.value}&headquarter=${headquarter}&type=${type}&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((actualData) => {
                setGroup(actualData.groups[0].titulo);
                setData(actualData);
                setIsLoading(false);
            });
    };

    const handleCourse = (e) => {
        setIsLoading(true);
        setCourse(e.target.value);
        fetch(`${process.env.REACT_APP_NODE_API}cargarActas?degree=${e.target.value}&years=${courseYear}&headquarter=${headquarter}&type=${type}&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((actualData) => {
                setGroup(actualData.groups[0].titulo);
                setData(actualData);
                setIsLoading(false);
            });
    };

    const handleType = (e) => {
        setIsLoading(true);
        setType(e.target.value);
        fetch(`${process.env.REACT_APP_NODE_API}cargarActas?degree=${course}&years=${courseYear}&headquarter=${headquarter}&type=${e.target.value}&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((actualData) => {
                setGroup(actualData.groups[0].titulo);
                setData(actualData);
                setIsLoading(false);
            });
    };

    const handleGroup = (e) => {
        setGroup(e.target.value);
    };

    const handleGenerate = () => {
        setIsProceedingLoading(true);
        const raw = JSON.stringify({
            "course": course,
            "courseYear": courseYear,
            "type": type,
            "headquarter": headquarter,
            "group": group,
            "fecha": fecha,
            "userEmail": userSession.profileObj.email,
            "usersId": alumnosIds
        });
        fetch(`${process.env.REACT_APP_NODE_API}buscarDatosActas`, {
            method: 'POST',
            body: raw,
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
            .then((response) => response.blob())
            .then((myBlob) => {
                const blobFinal = myBlob.slice(0, myBlob.size, "application/pdf");
                const url = URL.createObjectURL(blobFinal);
                window.open(url, '_blank');
                setIsLoading(false);
                setIsProceedingLoading(false);
            })
            .catch(error => {
                sessionStorage.setItem('apiError', error)
                localStorage.removeItem('user')
                window.location.href = '/';
            });
    };

    const handleProceeding = (e) => {
        setIsLoading(true);
        e.preventDefault();
        fetch(`${process.env.REACT_APP_NODE_API}buscarAlumnosActas?type=${type}&group=${group}&fecha=${fecha}&courseYear=${courseYear}&headquarter=${headquarter}&userEmail=${userSession.profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
        .then((response) => response.json())
        .then((actualData) => {
            actualData.forEach((element) => {
                element.actions = (
                  <Input
                    type="checkbox"
                    id={element.ccodcli}
                    name="option"
                    checked={alumnosIds.includes(element.ccodcli)}
                    onChange={() => handleStudent(element.ccodcli)}
                  />
                );
            });
            setListadoAlumnos(actualData);
            setAllChecked(false);
            setIsLoading(false);
        })
        .catch(error => {
            sessionStorage.setItem('apiError', error)
            localStorage.removeItem('user')
            window.location.href = '/';
        })
    }

    const handleCheck = () => {
        if (!allChecked) {
            // Marcar todos
            const nuevoArray = listadoAlumnos.map(a => a.ccodcli);
            setAlumnosIds(nuevoArray);
            
            // Actualizar listadoAlumnos para que todos los checkboxes estén marcados
            setListadoAlumnos(prevList =>
                prevList.map(alumno => ({
                    ...alumno,
                    actions: (
                        <Input
                            type="checkbox"
                            id={alumno.ccodcli}
                            name="option"
                            checked // Marcar checkbox
                            onChange={() => handleStudent(alumno.ccodcli)}
                        />
                    )
                }))
            );
        } else {
            // Desmarcar todos
            setAlumnosIds([]);
            
            // Actualizar listadoAlumnos para desmarcar todos los checkboxes
            setListadoAlumnos(prevList =>
                prevList.map(alumno => ({
                    ...alumno,
                    actions: (
                        <Input
                            type="checkbox"
                            id={alumno.ccodcli}
                            name="option"
                            checked={false}  // Desmarcar checkbox
                            onChange={() => handleStudent(alumno.ccodcli)}
                        />
                    )
                }))
            );
        }
        
        // Alternar el estado de allChecked
        setAllChecked(!allChecked);
    };

    const handleFecha = (value) => {
        const fechad = moment.utc(value).toDate();
        const formattedDate = fechad.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        setFecha(formattedDate);
    };

    return (
        <>
            {isProceedingLoading ? <ModalLoader header="Generando acta" /> : ''}
            {data !== '' ? <Form>
                <Row className="mb-3">
                    <Col sm>
                        <Label for="centros">
                            Centro
                        </Label>
                        <Input type="select" name="centros" id="centros" onChange={handleHeadquarter} disabled={isLoading ? 'disabled' : ''}>
                            {data.headquarters.map(datos => <option key={datos} value={datos}>{datos}</option>)}
                        </Input>
                    </Col>
                    <Col sm className="mt-2 mt-md-0">
                        <Label for="grado">
                            Grado
                        </Label>
                        <Input type="select" name="grado" id="grado" onChange={handleCourse} disabled={isLoading ? 'disabled' : ''}>
                            {data.degree.map(datos => <option key={datos.value} value={datos.value}>{datos.name}</option>)}
                        </Input>
                    </Col>
                </Row>
                <Row className="mt-0 mt-md-2 mb-3">
                    <Col sm className="mt-2 mt-md-0">
                        <Label for="curso">
                            Curso
                        </Label>
                        <Input type="select" name="curso" id="curso" onChange={handleCourseYear} disabled={isLoading ? 'disabled' : ''}>
                            {data.years.map(datos => <option key={datos.value} value={datos.value}>{datos.name}</option>)}
                        </Input>
                    </Col>
                    <Col sm className="mt-2 mt-md-0">
                        <Label for="tipo">
                            Tipo
                        </Label>
                        <Input type="select" name="tipo" id="tipo" onChange={handleType} disabled={isLoading ? 'disabled' : ''}>
                            {data.type.map(datos => <option key={datos.value} value={datos.value}>{datos.name}</option>)}
                        </Input>
                    </Col>
                </Row>
                <Row className="mt-0 mt-md-2">
                    <Col sm className="mt-2 mt-md-0">
                        <Label for="grupo">
                            Grupo
                        </Label>
                        <Input type="select" name="grupo" id="grupo" disabled={isLoading ? 'disabled' : ''} onChange={handleGroup}>
                            {data.groups.length > 0 ? data.groups.map(datos => <option key={datos.titulo} value={datos.titulo}>{datos.titulo}</option>) : ''}
                        </Input>
                    </Col>
                    <Col sm className="mt-2 mt-md-0">
                        <Label for="fecha">
                            Fecha
                        </Label>
                        <Datetime id="fecha" name="fecha" timeFormat={false} onChange={handleFecha} dateFormat/>
                        {fecha !== '' ? '' : <span className="text-danger tiny">*Obligatorio</span>}
                    </Col>
                </Row>
                <Row className="mt-0 mt-md-2">
                    <Col sm className="mt-6 mt-md-0 text-start">
                        <Button className={fecha !== '' && data.groups.length > 0 ? "mt-3 w-auto" : "mt-3 w-auto disabled"} onClick={handleProceeding}>{isLoading ? <><Spinner color="primary" style={{ height: '15px', width: '15px' }} />{' '}</> : 'Filtrar'}</Button>
                    </Col>
                    <Col sm className="mt-6 mt-md-0 text-center">
                        <Button className={listadoAlumnos.length > 0 && data.groups.length > 0 && !isLoading ? "mt-3 w-auto" : "mt-3 w-auto disabled"} onClick={handleCheck}>{allChecked ? 'Desmarcar todos' : 'Marcar todos'}</Button>
                    </Col>
                    <Col sm className="mt-3 mt-md-0 text-end">
                        <Button className={alumnosIds.length > 0 && data.groups.length > 0 && !isLoading ? "mt-3 w-auto" : "mt-3 w-auto disabled"} onClick={handleGenerate}>Generar acta</Button>
                    </Col>
                </Row>
            </Form> : <Loader />}
            {listadoAlumnos.length > 0 ? <ListadoAlumnosActas data={listadoAlumnos}/> : ''}
        </>
    );
};

export default FormularioActa;
