import { useState, useEffect, useContext } from 'react';
import { TabPane, Row, Col, Form, Button, Input, Label } from 'reactstrap';
import { useFormik } from 'formik';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Icon from 'react-feather';
import 'moment/locale/es';
import ModalClickable from '../../layouts/modals/ModalClickable';
import ModalOptions from '../../layouts/modals/ModalOptions';
import { AuthContext } from '../../context/authContext';


const disableFutureDates = current => {

    // Obtiene la fecha actual
    const today = new Date();
  
    // Compara la fecha actual con la fecha seleccionada
    return current.isBefore(today);
  
};

const DatosEscolares = ({ prevActiveTab, datosEscolares, missingDataFunction, userId, datosPersonales }) => {

    // Declaramos variables
    const lastUpdate = new Date();
    const { empresaId } = JSON.parse(localStorage.getItem('student'));
    const { userOU } = useContext( AuthContext );
    const { idCedesca, idCntec, centro } = userOU;

    // Declaramos estados
    const [isModalShown, setIsModalShown] = useState(false);
    const [isModalShown2, setIsModalShown2] = useState(false);
    const [formationCenter, setFormationCenter] = useState('');
    const [academicFormation, setAcademicFormation] = useState('');
    const [studiesList, setStudiesList] = useState('');
    const [studiesList2, setStudiesList2] = useState('');
    const [sitLaboralList, setSitLaboralList] = useState([]);

    const [lastStudiesList, setLastStudiesList] = useState('');
    const [yearsApi, setYearsApi] = useState('');
    const [raw, setRaw] = useState('');
    const [url, setUrl] = useState('');
    const [nese, setNese] = useState('')


    // Declaramos la función de validación
    const validate = (values) => {

        // Preparamos variable de errores
        const errors = {};

        // Validamos el idioma
        if(values.idioma === ''){
            errors.idioma = 'Obligatorio';
            missingDataFunction(true);
        }
    
        // Validamos el sede examen
        if(!values.sede) {
            errors.sede = 'Obligatorio';
            missingDataFunction(true);
        }
    
        // Validamos la nota media (no obligatorio)
        if (values.notaMedia.toString().length > 0 && !/^\d*\.?\d+$/i.test(values.notaMedia)){
            errors.notaMedia = 'Debe ser un valor decimal separado por un punto';
            missingDataFunction(true);
        }
    
        // Validamos los estudios siguientes
        if(!values.siguientesEstudios) {
            errors.siguientesEstudios = 'Obligatorio';
            missingDataFunction(true);
        }
    
        // Validamos la forma de acceso
        if(values.formaAcceso === ''){
            errors.formaAcceso = 'Obligatorio';
            missingDataFunction(true);
        }

        // Devolvemos los errores
        return errors

    }

    const saveData = (values, whitModal = true) => {
        // Preparamos variables
        const userSession = JSON.parse(localStorage.getItem('user'));
        const empresa = JSON.parse(localStorage.getItem('student')).empresaId;
        let isUpdated = 0;

        // Llamamos a la API para comprobar si se ha actualizado el usuario
        fetch(`${process.env.REACT_APP_NODE_API}comprobarActualizacion?empresaId=${empresa}&alumno=${userId}&userEmail=${userSession.profileObj.email}&date=${lastUpdate}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
        .then((response) => response.json())
        .then((data) => {

            isUpdated = data;

            if (isUpdated === 0) {
                setIsModalShown(true);
            } else {
                const urlToGo = `${process.env.REACT_APP_NODE_API}actualizarUsuarioEscolares?empresaId=${JSON.parse(localStorage.getItem('student')).empresaId}`
                // TODO: Configurar raw
                const rawData = {
                    id: userId,
                    idioma: values.idioma,
                    idalu: values.idalu,
                    sede: values.sede,
                    nee: values.nee,
                    tsi: values.tsi,
                    neeComment: values.neeComment,
                    situacionLaboral: values.situacionLaboral,
                    formacionAcademica: values.formacionAcademica,
                    antiguoCentro: values.antiguoCentro,
                    antiguosEstudios: values.antiguosEstudios,
                    notaMedia: values.notaMedia,
                    siguientesEstudios: values.siguientesEstudios,
                    formaAcceso: values.formaAcceso,
                    emailCorp: values.emailCorp,
                    fechaUltimoCurso: values.fechaUltimoCurso,
                    fechaTitulo: values.fechaTitulo,
                    userEmail: userSession.profileObj.email,
                    headquarter: JSON.parse(localStorage.getItem('student')).empresaId
                }

                if (whitModal) {
                    setRaw(rawData);
                    setUrl(urlToGo);
                    setIsModalShown2(true);
                } else {
                    const r = JSON.stringify(rawData)

                    fetch(`${urlToGo}`, {
                        method: 'POST',
                        body: `${r}`,
                        redirect: 'follow',
                        mode: 'cors',
                        cache: "no-store",
                        referrerPolicy: "no-referrer",
                        credentials: "same-origin",
                        headers: {
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                            'content-type': 'application/json',
                            'Cache-Control': 'no-cache, no-store, must-revalidate',
                            'Pragma': 'no-cache',
                            'Expires': '0'
                        }
                    })
                        .then((response) => response.json())
                        .then(() => {
                        // Nada que hacer de momento.
                        })
                        .catch(error => {
                            sessionStorage.setItem('apiError', error)
                            localStorage.removeItem('user')
                            window.location.href = '/';
                        })
                }
            }
        });
    }

    // Variable de Formik
    const formik = useFormik({
        initialValues: {
            id: userId,
            idioma: (datosEscolares.idioma === '' || datosEscolares.idioma === null) ? 'Español' : datosEscolares.idioma,
            idalu: datosEscolares.idalu === null ? '' : datosEscolares.idalu,
            sede: datosEscolares.sede === null ? '' : datosEscolares.sede,
            nee: datosEscolares.nee === null ? '' : datosEscolares.nee,
            tsi: datosEscolares.tsi === null ? '' : datosEscolares.tsi,
            neeComment: datosEscolares.neeComment === null ? '' : datosEscolares.neeComment,
            situacionLaboral: datosEscolares.situacionLaboral === null ? '' : datosEscolares.situacionLaboral,
            formacionAcademica: datosEscolares.formacionAcademica === null ? '' : datosEscolares.formacionAcademica,
            antiguoCentro: datosEscolares.antiguoCentro === null ? '' : datosEscolares.antiguoCentro,
            antiguosEstudios: datosEscolares.antiguosEstudios === null ? '' : datosEscolares.antiguosEstudios,
            notaMedia: datosEscolares.notaMedia === null ? '' : datosEscolares.notaMedia,
            siguientesEstudios: datosEscolares.siguientesEstudios === null ? '' : datosEscolares.siguientesEstudios,
            formaAcceso: datosEscolares.formaAcceso === null ? '' : datosEscolares.formaAcceso,
            emailCorp: datosEscolares.emailCorp === null ? '' : datosEscolares.emailCorp,
            fechaUltimoCurso: datosEscolares.fechaUltimoCurso === null ? '' : datosEscolares.fechaUltimoCurso,
            ...datosEscolares
        },
        validateOnChange: false,
        validate,
        onSubmit: (values) => saveData(values, true)
    });

    // Declaramos los efectos
    useEffect(() => {

        // Peticiones API
        fetch(`${process.env.REACT_APP_NODE_API}cargarCentrosFormacion?empresaId=CEDESCA&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
        .then((response) => response.json())
        .then((actualData) => {
            if (datosPersonales.centroNombre === 'CAMPUS ACADEMIA'){
                const filteredData = actualData.filter(entry => entry.Nombre === "CAMP JOLIU" || entry.Nombre === "CEDESCA");
                setFormationCenter([{ Id: "-1", Nombre: "", Domicilio: "", Poblacion: "", Provincia: "", Distrito: "", Telefono1: "", Telefono2: "", Fax: "", Email: "", Director: "", CCC: "", sufijo: "", numfactura: "", archivos: "", tipo: "" }, ...filteredData]);

            }else{
                setFormationCenter([{ Id: "-1", Nombre: "", Domicilio: "", Poblacion: "", Provincia: "", Distrito: "", Telefono1: "", Telefono2: "", Fax: "", Email: "", Director: "", CCC: "", sufijo: "", numfactura: "", archivos: "", tipo: "" }, ...actualData]);

            }
        });
        fetch(`${process.env.REACT_APP_NODE_API}obtenerOpcionesNese?empresaId=CEDESCA&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
        .then((response) => response.json())
        .then((actualData) => {

                setNese([{ Id: "-1", tipo: "", tipo_cast: "", codigo: "",}, ...actualData]);

            
        });
        fetch(`${process.env.REACT_APP_NODE_API}cargarAEscolares?empresaId=${empresaId}&headquarter=${empresaId}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
        .then((response) => response.json())
        .then((actualData) => {
    
            const arrayFiltrado = actualData;
            const nuevoObjeto = { Id: '-1' , Valor: '', Valor2: ''};
            arrayFiltrado.unshift(nuevoObjeto);
            setYearsApi(arrayFiltrado);
    
        });

        fetch(`${process.env.REACT_APP_NODE_API}cargarEstudios?empresaId=${empresaId}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}&procedence=1`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
        .then((response) => response.json())
        .then((actualDataa) => {
            setStudiesList2([{Id_Estudio: 0, Estudio: '', Estudio_cast: '', Codigo_Estudios: 0}, ...actualDataa]);
        });

        fetch(`${process.env.REACT_APP_NODE_API}cargarEstudios?empresaId=${empresaId}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
        .then((response) => response.json())
        .then((actualDataa) => {
            setStudiesList([{Id_Estudio: 0, Estudio: '', Estudio_cast: '', Codigo_Estudios: 0}, ...actualDataa]);
        });

        fetch(`${process.env.REACT_APP_NODE_API}cargarUltimosEstudios?empresaId=${empresaId}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
        .then((response) => response.json())
        .then((actualDataa) => {
    
            const arrayFiltrado = actualDataa;
            const nuevoObjeto = { Id: '-1' , name: '' };
            arrayFiltrado.unshift(nuevoObjeto);
            setLastStudiesList(actualDataa);
    
        });

        fetch(`${process.env.REACT_APP_NODE_API}cargarFormacionesAcademicas?empresaId=${empresaId}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
        .then((response) => response.json())
        .then((actualData) => {
    
            const arrayFiltrado = actualData;
            const nuevoObjeto = { Id: '-1' , Nombre: '' };
            arrayFiltrado.unshift(nuevoObjeto);
            setAcademicFormation(arrayFiltrado);
    
        });

        fetch(`${process.env.REACT_APP_NODE_API}cargarSituacionLaboral?empresaId=${empresaId}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
        .then((response) => response.json())
        .then((actualDataa) => {
            setSitLaboralList(actualDataa);
        });

    },[]);

    // Efecto para setear las fechas en formato Moment
    useEffect(() => {

        const fecha = new Date(datosEscolares.fechaTitulo);
        const momentDate = moment(fecha);

        formik.setValues({
            ...formik.values,
            fechaTitulo: momentDate
        });

    },[]);

    // Efecto para activar la validación al cargar el componente
    useEffect(() => {
        formik.validateForm();
    }, [formik.values]);

    // Efecto para guardar al cambiar de pestaña y resetear el dirty de formik.
    useEffect(() => {
        if (prevActiveTab === "2") {
            if (formik.dirty) saveData(formik.values, false)
        } else {
            formik.resetForm({ values: formik.values })
        }
    }, [prevActiveTab])

    // Efecto para setear centro por defecto
    useEffect(() => {

        // Si el rol es de delegación, asignamos valor por defecto
        if ( formationCenter.length > 0 && idCedesca > 0 && idCntec > 0 ) {
            formik.setValues(prev => ({ ...prev, sede: centro }));
        }

    },[formationCenter]);

    // Declaramos las funciones
    const handleFechaTitulos = (value) => {
        formik.setValues( prev => ({ ...prev, fechaTitulo: value }));
    }

    return (
        <>
            { isModalShown ? <ModalClickable header="Un usuario ha modificado este alumno recientemente." /> : '' }
            { isModalShown2 ? <ModalOptions header="¿Desea realizar estos cambios?" url={ url } raw={ raw } load={ setIsModalShown2 } text1='Cancelar' color1='danger' text2='Aceptar' color2='primary'/> : '' }
            <TabPane tabId="2">
                <Form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                    <Row>
                        <Col md="2">
                            <Label htmlFor="idioma"><small>Idioma <span className='text-danger'>*</span></small></Label>
                            <Input
                                id="idioma"
                                name="idioma"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.idioma }
                                className={ formik.errors.idioma ? 'border border-danger error rounded ' : '' }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                <option key='0-idioma' value=''></option>
                                <option key='1-idioma' value='Español'>Español</option>
                                <option key='2-idioma' value='Catalán'>Catalán</option>
                            </Input>
                            { formik.errors.idioma ? <div className="text-danger tiny">{ formik.errors.idioma }</div> : null }
                        </Col>
                        <Col md="2">
                            <Label htmlFor="idalu"><small>IDALU</small></Label>
                            <Input
                                id="idalu"
                                name="idalu"
                                type="text"
                                onChange={ formik.handleChange }
                                value={ formik.values.idalu }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            />
                        </Col>
                        <Col md="3">
                            <Label htmlFor="sede"><small>Sede examen <span className='text-danger'>*</span></small></Label>
                            <Input
                                id="sede"
                                name="sede"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.sede }
                                disabled={(idCedesca > 0 && idCntec > 0 ) || userOU.centro === 'ORIENTACIÓN'}
                                className={ formik.errors.sede ? 'border border-danger error rounded ' : '' }
                            >
                                { formationCenter !== ''
                                    ? formationCenter.map((option) => (
                                        <option key={ `${ option.Id }-sedeExamen` } value={ option.Nombre }>
                                            { option.Nombre }
                                        </option>
                                    ))
                                    : ''
                                }
                            </Input>
                            { formik.errors.sede ? <div className="text-danger tiny">{ formik.errors.sede }</div> : null }
                        </Col>
                       
                        <Col md="3">
                            <Label htmlFor="tsi"><small>TSI </small></Label>
                            <Input
                                id="tsi"
                                name="tsi"
                                type="text"
                                onChange={ formik.handleChange }
                                value={ formik.values.tsi }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md="2">
                            <Label htmlFor="situacionLaboral"><small>Situación laboral</small></Label>
                            <Input
                                id="situacionLaboral"
                                name="situacionLaboral"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.situacionLaboral }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                <option key='0-sitLaboral' value=''></option>
                                {
                                    sitLaboralList.map((item, index) => (
                                        <option key={`${ index }-sitLaboral`} value={ item.Valor_cast }>{ item.Valor_cast }</option>
                                    ))
                                }
                            </Input>
                        </Col>
                        <Col md="3">
                            <Label htmlFor="formacionAcademica"><small>Curso realizado</small></Label>
                            <Input
                                id="formacionAcademica"
                                name="formacionAcademica"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.formacionAcademica }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                { academicFormation !== ''
                                    ? academicFormation.map((option) => (
                                        <option key={ `${ option.id }-formacionAcademica` } value={ option.Valor }>
                                            { option.Valor }
                                        </option>
                                    ))
                                    : ''
                                }
                            </Input>
                        </Col>
                        <Col md="3">
                            <Label htmlFor="antiguoCentro"><small>Centro de procedencia</small></Label>
                            <Input
                                id="antiguoCentro"
                                name="antiguoCentro"
                                type="text"
                                onChange={ formik.handleChange }
                                value={ formik.values.antiguoCentro }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            />
                        </Col>
                        <Col md="4">
                            <Label htmlFor="antiguosEstudios"><small>Estudios de procedencia</small></Label>
                            <Input
                                id="antiguosEstudios"
                                name="antiguosEstudios"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.antiguosEstudios }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                { studiesList2 !== ''
                                    ? studiesList2.map((option) => (
                                        <option key={ `${ option.Id_Estudio }-estudiosProcedencia` } value={ option.Estudio }>
                                        { option.Estudio_cast }
                                        </option>
                                    ))
                                    : ''
                                }
                            </Input>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md="2">
                            <Label htmlFor="notaMedia"><small>Nota media</small></Label>
                            <Input
                                id="notaMedia"
                                name="notaMedia"
                                type="text"
                                onChange={ formik.handleChange }
                                value={ formik.values.notaMedia }
                                className={ formik.errors.notaMedia ? 'border border-danger error' : '' }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            />
                            { formik.errors.notaMedia ? <div className="text-danger tiny">{ formik.errors.notaMedia }</div> : null }
                        </Col>
                        <Col md="3">
                            <Label htmlFor="fechaUltimoCurso"><small>Fecha último curso</small></Label>
                            <Input
                                id="fechaUltimoCurso"
                                name="fechaUltimoCurso"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.fechaUltimoCurso }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                { yearsApi !== ''
                                    ? yearsApi.map((option) => (
                                        <option key={ `${ option.Id }-fechaUltimoCurso` } value={ option.Valor }>
                                            { option.Valor }
                                        </option>
                                        ))
                                    : ''
                                }
                            </Input>
                        </Col>
                        <Col md="3">
                            <Label htmlFor="fechaTitulo"><small>Fecha obtención del título</small></Label>
                            <Datetime
                                utc
                                timeFormat={ false }
                                isValidDate={ disableFutureDates }
                                onChange={ handleFechaTitulos }
                                value={ formik.values.fechaTitulo }
                                locale="es"
                                inputProps={userOU.centro === 'ORIENTACIÓN' ? { disabled: true } : ''}
                            />
                        </Col>
                        <Col md="4">
                            <Label htmlFor="emailCorp"><small>Email corporativo</small></Label>
                            <Input
                                id="emailCorp"
                                name="emailCorp"
                                type="text"
                                disabled={ (idCedesca > 0 && idCntec > 0) || userOU.centro  === 'ORIENTACIÓN'}
                                onChange={ formik.handleChange }
                                value={ formik.values.emailCorp }

                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md="4">
                            <Label htmlFor="siguientesEstudios"><small>Estudios siguientes <span className='text-danger'>*</span></small></Label>
                            <Input
                                id="siguientesEstudios"
                                name="siguientesEstudios"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.siguientesEstudios }
                                className={ formik.errors.siguientesEstudios ? 'border border-danger error' : '' }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                { studiesList !== ''
                                    ? studiesList.map((option) => (
                                        <option key={ `${ option.Id_Estudio }-estudiosSiguientes` } value={ option.Estudio }>
                                            { option.Estudio_cast }
                                        </option>
                                    ))
                                    : ''
                                }
                            </Input>
                            { formik.errors.siguientesEstudios ? <div className="text-danger tiny">{ formik.errors.siguientesEstudios }</div> : null }
                        </Col>
                        <Col md="3">
                            <Label htmlFor="formaAcceso"><small>Forma de acceso <span className='text-danger'>*</span></small></Label>
                            <Input
                                id="formaAcceso"
                                name="formaAcceso"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.formaAcceso }
                                className={ formik.errors.formaAcceso ? 'border border-danger error' : '' }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                            >
                                { lastStudiesList !== ''
                                    ? lastStudiesList.map((option) => (
                                        <option key={ `${ option.Id }-formaAccesso` } value={ option.name }>
                                            { option.name_cast }
                                        </option>
                                        ))
                                    : ''
                                }
                            </Input>
                            { formik.errors.formaAcceso ? <div className="text-danger tiny">{ formik.errors.formaAcceso }</div> : null }
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                    <Col md="3">
                            <Label htmlFor="nee"><small>NESE</small></Label>
                            <Input
                                id="nee"
                                name="nee"
                                type="select"
                                onChange={ formik.handleChange }
                                value={ formik.values.nee }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                className={ formik.errors.nee ? 'border border-danger error rounded ' : '' }
                            >
                                 { nese !== ''
                                    ? nese.map((option) => (
                                        <option key={ `${ option.Id }-sedeExamen` } value={ option.tipo_cast }>
                                            { option.tipo_cast }
                                        </option>
                                    ))
                                    : ''
                                }
                           
                            { formik.errors.nee ? <div className="text-danger tiny">{ formik.errors.nee }</div> : null }
                                </Input>
                        </Col>
                        <Col md="7">
                        <Label htmlFor="neeComment"><small>Comentario nese</small></Label>
                            <Input
                                id="neeComment"
                                name="neeComment"
                                type="textarea"
                                onChange={ formik.handleChange }
                                value={ formik.values.neeComment }
                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                className={ formik.errors.neeComment ? 'border border-danger error rounded ' : '' }
                            />
                             { formik.errors.neeComment ? <div className="text-danger tiny">{ formik.errors.neeComment}</div> : null }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className="mt-1 mt-md-0">
                        {userOU.centro !== 'ORIENTACIÓN' ? <Button type="submit" color="success" className="mt-3 w-auto">
                                <Icon.Save />
                            </Button> : ''}
                            
                        </Col>
                    </Row>
                </Form>
            </TabPane>
        </>
    )
}

DatosEscolares.propTypes = {
    prevActiveTab: PropTypes.string,
    datosEscolares: PropTypes.any,
    missingDataFunction: PropTypes.any,
    datosPersonales: PropTypes.any,
    userId: PropTypes.string
  };

export default DatosEscolares;