import { useEffect, useState } from "react"
import { Input } from "reactstrap" 
import PropTypes from 'prop-types';

const EstadoRecibo = ({setIsLoading, isLoading, actual, setIdEditados, idEditados, numRecibo}) => {
    const [data, setData] = useState('')
    const userSession = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        fetch(`${process.env.REACT_APP_NODE_API}cargarEstadosRecibo?headquarter=CEDESCA&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((actualData) => {

                //setGroup(actualData.groups[0].titulo)
                setData(actualData)
                setIsLoading(false)
            }).catch(error => {
                sessionStorage.setItem('apiError', error)
                localStorage.removeItem('user')
                window.location.href = '/';
            })

    }, []);

 
    const handleOnChange = (e) => {
        const nuevoArray = idEditados.filter(objeto => objeto.id !== e.target.id);
        const reciboEditado = {
            id: e.target.id,
            estado: e.target.value,
        }
        setIdEditados([...nuevoArray, reciboEditado])
    }

    return (
        <>
            {isLoading || data.length === 0 ? '' : <Input type="select" name={numRecibo}  id={numRecibo}  onChange={handleOnChange} defaultValue={actual} style={{
        width: "93px",
        height: "30px",
        fontSize: "13px",
        padding: "5px",
      }}>
                            {data.map(datos => <option key={datos.id} value={datos.id}>{datos.descripcion}</option>)}
            </Input> }
            
        </>
    )
}
EstadoRecibo.propTypes = {
    isLoading: PropTypes.any,
    setIsLoading: PropTypes.any,
    actual: PropTypes.any,
    idEditados: PropTypes.any,
    setIdEditados: PropTypes.any,
    numRecibo: PropTypes.any
  };
export default EstadoRecibo