import { useState } from 'react';
import { Button, Col, Input, Row, Spinner } from 'reactstrap';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import ModalAviso from '../../layouts/modals/ModalAviso';

// Componente que renderiza la acción de suplantar.
const SelectionButton = ({ user = {}, handleImpersonation }) => {
  const { isVerified } = user;

  return (
    <Button
      color={isVerified ? 'success' : 'link'}
      type="button"
      onClick={() => handleImpersonation(user)}
      disabled={!isVerified}
    >
      {isVerified ? <Icon.LogIn /> : <Icon.Slash />}
    </Button>
  );
};

SelectionButton.propTypes = {
  user: {
    id: PropTypes.number,
    nombre: PropTypes.string,
    email: PropTypes.string,
    isVerified: PropTypes.bool,
  },
  handleImpersonation: PropTypes.func,
};

/**
 *
 * Botón (Buscar) -> Petición API para obtener listado de usuarios.
 *
 * ID
 * Nombre del usuario
 * Email
 *
 */
const FormularioSuplantacionIdentidad = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataApi, setDataApi] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  // Función para cargar los datos de usuarios
  const handleLoadData = async (e) => {
    e.preventDefault();
    setIsDataLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_API}buscarUsuarios?headquarter=CEDESCA&search=${searchUser}`,
        {
          method: 'get',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
            'content-type': 'application/json',
          },
        },
      );

      const responseData = (await response.json()) ?? [];
      setDataApi(responseData);
      setDataLoaded(true);
    } catch (error) {
      setDataApi([]);
      setDataLoaded(false);
    } finally {
      setIsDataLoading(false);
    }
  };

  // Función que abre el modal para confirmar la suplantación.
  const handleImpersonation = (user) => {
    setUserSelected(user);
    setShowModalConfirmation(true);
  };

  // Función que ejecuta el almacenamiento de la información del usuario.
  const execImpersonation = () => {
    const currentUserSession = JSON.parse(localStorage.getItem('user') ?? {});
    const { profileObj, tokenId } = currentUserSession;
    const newUserSession = {
      adminProfileObj: profileObj,
      profileObj: userSelected,
      tokenId,
      impersonating: true,
    };

    localStorage.setItem('user', JSON.stringify(newUserSession));
    window.location.href = '/';
  };

  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };

  const userColumns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Acciones',
      accessor: 'actions',
    },
  ];

  return (
    <>
      <Row md="12">
        <form onSubmit={handleLoadData}>
          <Row md="12">
            <Col md="3">
              <label htmlFor="searchsuplantacionidentidad" className="form-label">
                Selecciona un usuario
              </label>
              <Input
                type="text"
                name="text"
                id="searchsuplantacionidentidad"
                placeholder="Busca un usuario"
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
              />
            </Col>
            <Col md="9" style={{ alignSelf: 'flex-end' }}>
              <Button type="submit" style={{ marginRight: '16px' }} disabled={isDataLoading}>
                {isDataLoading ? (
                  <>
                    Cargando <Spinner color="warning" style={{ height: '15px', width: '15px' }} />
                  </>
                ) : (
                  'Buscar'
                )}
              </Button>
            </Col>
          </Row>
        </form>
        {dataLoaded && (
          <Col md="12">
            <ReactTable
              {...translations}
              columns={userColumns}
              data={dataApi.map((user) => ({
                ...user,
                actions: <SelectionButton user={user} handleImpersonation={handleImpersonation} />,
              }))}
              defaultPageSize={10}
              showPaginationBottom
              className="-striped -highlight text-center mt-3"
            />
          </Col>
        )}
      </Row>

      {/* Modal para confirmar la suplantación */}
      {showModalConfirmation && !!userSelected && (
        <ModalAviso
          toggle={execImpersonation}
          header={`Confirmar la suplantación a ${userSelected.email}`}
          onClose={() => setShowModalConfirmation(false)}
        />
      )}
    </>
  );
};

export default FormularioSuplantacionIdentidad;
