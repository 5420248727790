
import { useState, useEffect } from 'react'
import { Col, Form, Row, Input, Button, Label } from 'reactstrap'
import OptionsForm from '../../layouts/forms/OptionsForm'
import Loader from '../../layouts/loader/Loader'
import ModalClickable from '../../layouts/modals/ModalClickable'

const FormularioIntercambio = () => {
   
    const [isDownloaded, setIsDownloaded] = useState(false)
    const [dataApi, setDataApi] = useState('')
    const [headquarter, setHeadquarter] = useState('')
    const [courseYear, setCourseYear] = useState('')
    const [type, setType] = useState('')
    const [course, setCourse] = useState('')

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}cargarOpcionesSII.php`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((actualData) => {
                
                setDataApi(actualData)
                setHeadquarter(actualData.centre[0].codigo)
                setCourseYear(actualData.curs[0].codigo)
                setCourse(actualData.grau[0].codigo)
                setType(actualData.info[0].codigo)
            })
            .catch(error => {
                sessionStorage.setItem('apiError', error)
                localStorage.removeItem('user')
                window.location.href = '/';
            })
    }, [])

    const handleHeadquarter = (e) => {
        setHeadquarter(e.target.value)
    }
    const handleCourseYear = (e) => {
        setCourseYear(e.target.value)
    }
    const handleType = (e) => {
        setType(e.target.value)
    }
    const handleCourse = (e) => {
        setCourse(e.target.value)
    }

    const sendData = () => {

        setIsDownloaded(true)
        fetch(`${process.env.REACT_APP_NODE_API}generarXML?headquarter=${headquarter}&evaluaciones=${type}&ano=${courseYear}&grado=${course}&userEmail=${JSON.parse(localStorage.getItem('user')).profileObj.email}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        }
        )
            .then(response => response.json())

            .catch(error => {
                sessionStorage.setItem('apiError', error)
                localStorage.removeItem('user')
                window.location.href = '/';
            })
    }

    return (
        <>
            
            {isDownloaded ? <ModalClickable color="primary" header="Petición recibida correctamente. El archivo se enviará a su correo electrónico una vez esté generado" /> : ''}

            <Form >
                {dataApi !== '' ? <>
                    <Row className="mb-3">
                        <Col sm>
                            <Label for="centros">
                                Centro
                            </Label>
                            <Input type="select" name="centros" id="centros" onChange={handleHeadquarter}>
                                <OptionsForm datos={dataApi.centre} />
                            </Input>
                        </Col>
                        <Col sm className="mt-2 mt-md-0">
                            <Label for="tipo">
                                Tipo
                            </Label>
                            <Input type="select" name="tipo" id="tipo" onChange={handleType}>
                                <OptionsForm datos={dataApi.info} />
                            </Input>
                        </Col>
                    </Row>
                    <Row className="mt-0 mt-md-2">
                        <Col sm className="mt-2 mt-md-0">
                            <Label for="curso">
                                Curso
                            </Label>
                            <Input type="select" name="curso" id="curso" onChange={handleCourseYear}>
                                <OptionsForm datos={dataApi.curs} />
                            </Input>
                        </Col>
                        <Col sm className="mt-2 mt-md-0">
                            <Label for="grado">
                                Grado
                            </Label>
                            <Input type="select" name="grado" id="grado" onChange={handleCourse}>
                                <OptionsForm datos={dataApi.grau} />
                            </Input>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Button className="mt-3 w-auto" onClick={sendData}>Generar</Button>
                    </Row>
                </> : <Loader />}
            </Form >
        </>
    )
}

export default FormularioIntercambio