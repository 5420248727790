import React, { useState, useEffect,useContext } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  FormGroup,
  Label
} from 'reactstrap';

import PropTypes from 'prop-types';
import { AuthContext } from '../../context/authContext';
import ModalClickable from '../modals/ModalClickable';
import ModalOptions from '../modals/ModalOptions';


const ComponentCard = ({
  children,
  title,
  subtitle,
  dropdown,
  handleClick,
  name,
  isProfile,
  dataD2L
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [state, setState] = useState(true);
  const [isModalShown, setIsModalShown] = useState(false)
  const [isModalShown2, setIsModalShown2] = useState(false)
  const [raw, setRaw] = useState('');
  const [url, setUrl] = useState('');
  const [header, setHeader] = useState('')
  const { userOU } = useContext(AuthContext);
  useEffect(() =>{ if (typeof dataD2L !== 'undefined' && !dataD2L.error) setState(dataD2L.Activation.IsActive)}, [dataD2L])
  const userSession = JSON.parse(localStorage.getItem('user'));

  const toggle = () => setDropdownOpen(!dropdownOpen);
  
  
  return (
    <>
     { isModalShown2 ? <ModalClickable header={header} /> : '' }
     { isModalShown ? <ModalOptions header="¿Desea cambiar el estado de alumno en el campus?" url={ url } raw={ raw } load={ setIsModalShown } text1='Cancelar' color1='danger' text2='Aceptar' color2='primary' isD2L setNewModal={setIsModalShown2} setHeader={setHeader}/> : '' }
     <Card color="white">
      <CardTitle tag="h4" className="border-bottom px-4 py-3 mb-0">
        <div className="d-flex justify-content-between align-items-center">
          {dropdown === true ? (
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={toggle.bind(null)}
              className="list-unstyled"
            >
              <DropdownToggle nav caret>
                {title}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleClick}>Ir al listado</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <>{title}</>
          )}
          {name && <span className="ms-3">{name}</span>} 
          {isProfile && (userOU.centro === 'SUPERADMIN' || userOU.centro === 'SECRETARÍA') ? (
            <FormGroup switch className="d-flex align-items-center ms-3">
             <Label check className="me-5">Activo en campus</Label>
<Input
  type="switch"
  checked={state}
  style={{
    backgroundColor: state ? '#53e17a' : '',
    outline: 'none'        // Fondo verde o rojo según el estado
  }}
  onClick={() => {
    setState(!state);
    setIsModalShown(true);
    setIsModalShown2(false);
    const urlToGo = `${process.env.REACT_APP_NODE_API}cambiarEstadoAlumnoD2L?userEmail=${userSession.profileObj.email}&headquarter=1`;
    const rawData = {
      id: dataD2L.UserId,
      isActive: !state
    };
    setRaw(rawData);
    setUrl(urlToGo);
  }}
/>
          </FormGroup>
          ) : ''}
        </div>
      </CardTitle>

      <CardBody className="p-4">
        <CardSubtitle className="text-muted mb-3">{subtitle || ''}</CardSubtitle>
        <div>{children}</div>
      </CardBody>
    </Card>
     </>
    
  );
};

ComponentCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  dropdown: PropTypes.bool,
  handleClick: PropTypes.any,
  name: PropTypes.string,
  isProfile: PropTypes.bool,
  dataD2L: PropTypes.any
};

export default ComponentCard;