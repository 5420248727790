import { useEffect, useRef, useState } from 'react';
import { Button, Col, Input, Progress, Row, Spinner } from 'reactstrap';
import ReactTable from 'react-table-v6';

const FormularioMotorCalculo = () => {

    /**
     * 
     * Botón (Cargar Datos) -> Petición API para obtener listado de matrículas
     * Botón (Ejecutar cálculo) -> Petición API para ejecutar calculo
     * 
     * ID
     * Nombre de alumno
     * IdPlan
     * Nº matrícula
     * Nota ciclo
     * Estado ('', 'En progreso', 'OK')
     * 
     */

    const canceladoRef = useRef(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataApi, setDataApi] = useState([]);
    const [anoAcademico, setAnoAcademico] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordsProcessed, setRecordsProcessed] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [startTime, setStartTime] = useState(Date.now());
    const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(null);

    // Mensajes de estado
    const estadoOk = <><b style={{ color: '#0bb7af' }}>OK</b></>;
    const estadoKo = <><b style={{ color: '#f64e60' }}>KO</b></>;
    const estadoProc = <><b style={{ color: '#ee9d01' }}>Procesando</b> <Spinner color="warning" style={{ height: '15px', width: '15px' }} /></>;

    // Peticiones API
    useEffect(() => {

        fetch(`${ process.env.REACT_APP_NODE_API }mostrarCursoActual?headquarter=CEDESCA&userEmail=${ JSON.parse(localStorage.getItem('user')).profileObj.email }`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((data) => {
            setAnoAcademico(data);
        })
        .catch(error => {
            sessionStorage.setItem('apiError', error)
            localStorage.removeItem('user')
            window.location.reload()
        });

    },[]);

    // Efecto para calcular el tiempo estimado
    useEffect(() => {

        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        const speed = recordsProcessed / elapsedTime; // registros procesados por milisegundo
        const remainingRecords = totalRecords - recordsProcessed;
        const remainingTime = remainingRecords / speed; // tiempo restante en milisegundos
    
        // Convertir el tiempo restante a minutos y segundos
        const minutes = Math.floor(remainingTime / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
    
        setEstimatedTimeRemaining(`${minutes} minutos y ${seconds} segundos`);

    }, [recordsProcessed, totalRecords, startTime]);

    // Efecto para calcular el porcentaje de la barra
    useEffect(() => {
        setProgressPercentage( (recordsProcessed * 100) / totalRecords );
    },[recordsProcessed]);

    // Función para cargar los datos con simulación de retardo del back
    const handleLoadData = () => {

        setIsDataLoading(true);

        fetch(`${ process.env.REACT_APP_NODE_API }mostrarAlumnos?headquarter=CEDESCA&userEmail=${ JSON.parse(localStorage.getItem('user')).profileObj.email }`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((data) => {

            // Guardamos los datos recibidos
            setDataApi(data);

            // Seteamos los estados
            setIsDataLoading(false);
            setDataLoaded(true);
            setTotalRecords(data.length);

        })
        .catch(error => {
            sessionStorage.setItem('apiError', error)
            localStorage.removeItem('user')
            window.location.reload()
        });

    }

    // Función para cancelar el proceso de notas
    const handleCancel = () => {
        canceladoRef.current = true;
    }

    // Función para procesar registro
    const procesarRegistro = async (i) => {

        // Si se ha cancelado el motor de cálculo, salimos del bucle
        if ( canceladoRef.current ) {
            
            return;
        }

        // Seteamos el registro actual como procesando (evitando mutación del estado)
        const updatedDataApi = [...dataApi];
        updatedDataApi[i].estado = estadoProc;
        setDataApi(updatedDataApi);

        // Preparamos el cuerpo del registro a enviar
        const { id, idplan, centro } = updatedDataApi[i];
        const raw = JSON.stringify({ id, idplan, centro });

        // Hacemos la petición a la API para procesar el registro actual
        try {

            const response = await fetch(`${ process.env.REACT_APP_NODE_API }calcularNotas`, {
                method: 'post',
                body: raw,
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).tokenId}`,
                    'content-type': 'application/json'
                }
            });
            const data = await response.json();

            // Seteamos el registro actual como procesado (evitando mutación del estado)
            updatedDataApi[i].estado = estadoOk;
            updatedDataApi[i].notaCiclo = data.notaCiclo;
            setDataApi(updatedDataApi);

            // Actualizamos la barra de progreso
            setRecordsProcessed( prev => prev + 1 );

        } catch( error ) {

            // Seteamos el registro actual como procesado (evitando mutación del estado)
            updatedDataApi[i].estado = estadoKo;
            setDataApi(updatedDataApi);

            // Actualizamos los registros procesados
            setRecordsProcessed( prev => prev + 1 );

        }

        // Llama recursivamente a la función para el siguiente registro
        if (i < dataApi.length) {
            await procesarRegistro(i + 1);
        }

    }

    // Función que controla la ejecución del procesamiento de registros
    const handleRun = async () => {

        // Empieza el proceso. Lo notificamos con el estado
        canceladoRef.current = false;
        setIsRunning(true);
        setStartTime(Date.now());

        // Ejecutamos la función recursiva
        await procesarRegistro(0);

        // El proceso ha terminado. Lo notificamos con el estado
        setIsRunning(false);

    }

    const translations = {
        previousText: '<',
        nextText: '>',
        loadingText: 'Cargando..',
        ofText: 'de',
        rowsText: 'líneas',
        noDataText: 'Datos no encontrados',
        pageText: 'Página',
    };

    return (
        <>
            <Row md="12">
                <Col md="3">
                    <label htmlFor="anoacademico" className="form-label">Año académico actual</label>
                    <Input
                        type="text"
                        name="text"
                        id="anoacademico"
                        placeholder="Año académico actual"
                        value={ anoAcademico }
                        disabled
                    />
                </Col>
                <Col md="9" style={{ alignSelf: 'flex-end' }}>
                    <Button color="primary" type="button" style={{ marginRight: '16px' }} onClick={ handleLoadData } disabled={ isDataLoading }>
                        { isDataLoading ? <>Cargando <Spinner color="warning" style={{ height: '15px', width: '15px' }} /></> : 'Cargar datos' }
                    </Button>
                    {
                        isRunning ? (
                            <Button color="danger" type="button" onClick={ handleCancel }>
                                Cancelar cálculo
                            </Button>
                        ) : (
                            <Button color="primary" type="button" disabled={ dataApi.length === 0 || isRunning } onClick={ handleRun }>
                                Ejecutar cálculo
                            </Button>
                        )
                    }
                </Col>
                {
                    dataLoaded && (
                        <Col md="12">
                                <ReactTable
                                    {...translations}
                                    columns={[
                                        {
                                            Header: 'ID',
                                            accessor: 'id',
                                        },
                                        {
                                            Header: 'Nombre de alumno',
                                            accessor: 'alumnoName',
                                            width: 420, 
                                            style: { textAlign: 'left' }
                                        },
                                        {
                                            Header: 'ID Plan',
                                            accessor: 'idplan',
                                        },
                                        {
                                            Header: 'Nº matrícula',
                                            accessor: 'numMatricula',
                                        },
                                        {
                                            Header: 'Nota ciclo',
                                            accessor: 'notaCiclo',
                                        },
                                        {
                                            Header: 'Estado',
                                            accessor: 'estado',
                                        }
                                    ]}
                                    data={ dataApi }
                                    defaultPageSize={10}
                                    showPaginationBottom
                                    className="-striped -highlight text-center mt-3"
                                />
                        </Col>
                    )
                }
                {
                    isRunning && (
                        <Col md="12" className="mt-3">
                            <label htmlFor="progress" className="form-label">Progreso total</label>
                            <Progress
                                animated
                                color={ progressPercentage === 100 ? 'success' : 'primary' }
                                value={ progressPercentage }
                                style={{ height: '25px' }}
                            />
                        </Col>
                    )
                }
            </Row>
            {
                isRunning && (
                    <Row md="12" className='mt-2'>
                        <Col md="9">
                            {
                                progressPercentage === 100
                                    ? 'Cálculo completado'
                                    : `${ recordsProcessed } de ${ totalRecords } registros procesados`
                            }
                        </Col>
                        <Col md="3">
                            <p>ETA: { estimatedTimeRemaining }</p>
                        </Col>
                    </Row>
                )
            }
        </>
    )
}

export default FormularioMotorCalculo;