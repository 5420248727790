import { React, useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { USER_ROLES } from '../helpers/constants';

export const AuthContext = createContext('hola');

const initialState = null;
const initialStateOU = { centro: '', id: '', idCedesca: -4, idCntec: -4 };

export const ContextProvider = (props) => {
  // Seteamos variables de estado necesarias
  const [user, setUser] = useState(initialState);
  const [userOU, setUserOU] = useState(initialStateOU);

  /*** FUNCIONES ***/

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem('user'));
    if (userSession !== null) {
      fetch(`${process.env.REACT_APP_NODE_API}getOrgUnit?userEmail=${userSession.profileObj.email}`, {
        timeout: 0,
        headers: {
          Authorization: `Bearer ${userSession.tokenId}`,
        },
      })
        .then((responses) => responses.json())
        .then((data) => {
          setUserOU(data.data);
        });
    }
    //Obtenemos la información de la unidad organizativa del usuario
  }, []);

  // Función para hacer el login con Google
  const loginGoogle = async ({ tokenId, profileObj }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_API}auth`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ tokenId, profileObj }),
      });
      const data = await response.json();
      const tokenJWT = data?.data?.token;

      // Almacenamos la información del usuario
      const newUserInformation = { tokenId: tokenJWT, profileObj, impersonating: false };
      setUser(newUserInformation);
      localStorage.setItem('user', JSON.stringify(newUserInformation));
      return true;
    } catch (error) {
      return false;
    }
  };

  // Función para obtener la OU
  const getUserOU = async () => {
    const userSession = JSON.parse(localStorage.getItem('user'));
    //Obtenemos la información de la unidad organizativa del usuario
    fetch(`${process.env.REACT_APP_NODE_API}getOrgUnit?userEmail=${userSession.profileObj.email}`, {
      timeout: 0,
      headers: {
        Authorization: `Bearer ${userSession.tokenId}`,
      },
    })
      .then((responses) => responses.json())
      .then((data) => {
        setUserOU(data.data);
      });
  };

  // Función para cerrar la sesión
  const logoutGoogle = () => {
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  // Función que devuelve el rol del usuario logueado.
  const getUserRol = () => {
    const isSuperadmin = userOU.idCedesca === 0 && userOU.idCntec === 0;
    if (isSuperadmin) return USER_ROLES.SUPERADMIN;
    const isSede = userOU.idCedesca === -1 && userOU.idCntec === -1;
    if (isSede) return USER_ROLES.SEDE;
    const isOrientacion = userOU.idCedesca === -2 && userOU.idCntec === -2;
    if (isOrientacion) return USER_ROLES.ORIENTACION;
    const isSecretaria = userOU.idCedesca === -3 && userOU.idCntec === -3;
    if (isSecretaria) return USER_ROLES.SECRETARIA;
    const isDelegacion = userOU.idCedesca > 0 && userOU.idCntec > 0;
    if (isDelegacion) return USER_ROLES.DELEGACION;
  };

  return (
    <>
      {userOU.idCedesca !== -4 ? (
        <AuthContext.Provider
          value={{
            user,
            loginGoogle,
            logoutGoogle,
            userOU,
            getUserOU,
            getUserRol,
          }}
        >
          {props.children}
        </AuthContext.Provider>
      ) : (
        <AuthContext.Provider
          value={{
            user,
            userOU,
            loginGoogle,
            logoutGoogle,
            getUserOU,
            getUserRol,
          }}
        >
          {props.children}
        </AuthContext.Provider>
      )}
    </>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node,
};
